import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import js from '../images/links/sheep.png'
import lh from '../images/links/lit_hist.png'


const Podcast = () => (
 <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">they came from the distant cosmos… or in some cases Pensylvania</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container links is-fluid">
        <h2 className="is-size-4">Other cool sites to visit</h2>

        <h3 className="is-size-5"><a href="http://jupitersheep.com" target="_blank">The Jupiter Sheep</a></h3>
        <div className="columns">
          <div className="column is-one-third pic">
            <a href="http://jupitersheep.com" target="_blank">
              <img src={js} className="episode-art" alt="Jupiter Sheep on stage during filming of Radio Sol video" />
            </a>
          </div>
          <div className="column is-two-thirds">
            <p>
              Highly original progressive rock act out of Sacramento in the early nineties.
              Hear the music, read the amazing lyrics, meet the band, hear the stories, see more whacky pictures.
            </p>
            <p>
              Features all three principle performers from the Max Wyvern Band as Byron Callisto, Missy Io, and Max Wyvern as himself.
            </p>
          </div>
        </div>

        <h3 className="is-size-5"><a href="http://literatureandhistory.com" target="_blank">Literature and History Podcast</a></h3>
        <div className="columns">
          <div className="column is-one-third pic">
            <a href="http://literatureandhistory.com" target="_blank">
              <img src={lh} className="episode-art" alt="Library with books everywhere" />
            </a>
          </div>
          <div className="column is-two-thirds">
            <p>
              Outstanding educational podcast on the history of anglophone literature. Starts with ancient Sumeria,
              goes into Egyptian stories, archaic Greek, Old Testament, then dives deep into classic
              Greece and Rome. Features detailed analysis of great works such as the Epic of Gilgamesh, the Book of the Dead,
              Homer, Hesiod, the classic tragedians, and early Roman poets. Now up to episode 61 on Ovid's Metamorphoses.
            </p>
            <p>
              Features extensive research, original music, and commentary by Doug Metzger, narrator of Planet and Sky Podcast.
            </p>
          </div>
        </div>



      </div>
    </section>
  </Layout>
)

export default Podcast
